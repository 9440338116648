import { TOKEN } from "@/constants/constants";
import authService from "@/services/Auth/authService";
import { handleError, handleSuccess } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    user: {}, // this contains the user's token, roles and permissions
    userDetails: {}, // this contains the user's personal details
    notifications: [
      {
        image: require("@/assets/notification.svg"),
        title: "Abayomi Ola",
        message:
          "Hello doctor, thank you for adding me as an admin to Betacare",
      },
      {
        image: require("@/assets/notification.svg"),
        title: "Abayomi Ola",
        message:
          "Hello doctor, thank you for adding me as an admin to Betacare",
      },
      {
        image: require("@/assets/notification.svg"),
        title: "Abayomi Ola",
        message:
          "Hello doctor, thank you for adding me as an admin to Betacare",
      },
    ],
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_ADMIN_DETAILS(state, value) {
      state.userDetails = value;
    },
  },
  actions: {
    setUser: ({ commit }, value) => {
      sessionStorage.setItem("userData", JSON.stringify(value));
      sessionStorage.setItem("token", value.token);
      commit("SET_USER", value);
    },

    fetchAdminDetails: async ({ commit }, value) => {
      try {
        const response = await authService.fetchAdminDetails(
          "/admin/details",
          value
        );
        sessionStorage.setItem("ADMIN_DETAILS", JSON.stringify(response));
        commit("SET_ADMIN_DETAILS", response);
      } catch (error) {
        handleError(error.message);
      }
    },

    updateProfile: async ({ commit, dispatch }, value) => {
      try {
        const response = await authService.updateProfile(
          "/admin/details/update",
          value
        );
        commit("SET_ADMIN_DETAILS", response);
        handleSuccess(response.message);
        await dispatch("fetchAdminDetails", { token: TOKEN });
        setTimeout(() => location.reload(), 1000);
      } catch (error) {
        handleError(error.message);
      }
    },
  },
};
