import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import request from "./request";
import subadmin from "./subadmin";
import activity from "./activity";
import graph from "./graph";
import dailytext from "./dailytext";
import pricing from "./pricing";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    request,
    subadmin,
    activity,
    graph,
    dailytext,
    pricing
  },
});
