import { api } from "@/api/api";
import { HEADERS } from "@/constants/constants";

export default {
  async fetchAdminSubusers(url) {
    return api.get(url, {
      headers: HEADERS,
    });
  },

  async postAdminSubUsers(url, data) {
    return api.post(url, data, {
      headers: HEADERS,
    });
  },

  async deleteAdminSubUsers(url) {
    return api.delete(url, {
      headers: HEADERS,
    });
  },
};
