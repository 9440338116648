<template>
  <div>
    <div class="my-3">
      <v-btn :color="BRANDCOLOR" depressed small @click="goBack" fab>
        <v-icon class="white--text">mdi-arrow-left </v-icon>
      </v-btn>
    </div>

    <div>
      <RevenueGraphSection :revenue="revenue"/>
    </div>

    <div>
      <RevenueStat />
    </div>

    <div>
      <RevenuePerCountry :revenue="revenue"/>
    </div>
  </div>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";
import RevenueGraphSection from "./RevenueGraphSection.vue";
import RevenuePerCountry from "./RevenuePerCountry.vue";
import RevenueStat from "./RevenueStat.vue";
export default {
  props: {
    revenue: {
      type: Object,
    },
  },
  data() {
    return {
      BRANDCOLOR,
    };
  },

  components: {
    RevenueGraphSection,
    RevenuePerCountry,
    RevenueStat,
  },

  methods: {
    goBack() {
      this.$emit("goBack");
    },
  },
};
</script>