<template>
  <DashboardLayout>
    <div>
      <div class="my-6 d-flex justify-space-between">
        <h2 class="admin-table-title">
          Daily SMS content ({{ dailytexts && dailytexts.length }})
        </h2>
        <div class="">
          <PrimaryButton
            @click="addNew"
            :color="BRANDCOLOR"
            :outlined="true"
            class="mr-2"
          >
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Add
            New</PrimaryButton
          >
          <PrimaryButton
            @click="addBulkSMS"
            :color="BRANDCOLOR"
            :outlined="true"
          >
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Send
            Bulk SMS</PrimaryButton
          >
        </div>
      </div>
      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(text, i) in dailytexts" :key="i">
          <td>{{ text.id }}</td>
          <td>{{ text.createdDate }}</td>
          <td>{{ text.lastModifiedDate }}</td>
          <td>{{ text.content }}</td>
          <td>
            <DailyTextMenu
              @setLoading="setLoading"
              :dailytext="text"
              @updateDailyText="updateDailyText"
            />
          </td>
        </tr>
      </AdminTable>

      <div class="my-8 d-flex justify-space-between">
        <PrimaryButton
          @click="scheduletext = !scheduletext"
          :class="'blue--text'"
          :large="true"
          :color="'#E9EEF9'"
        >
          Schedule Message
        </PrimaryButton>
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <v-dialog v-model="addnewbulksms" max-width="430px" :key="modalkey">
      <AddNewBulkSMS
        ref="addNewBulkSMS"
        @closeModal="addnewbulksms = !addnewbulksms"
        :loading="loading"
        @postBulkSMS="postBulkSMS"
      />
    </v-dialog>
    <v-dialog v-model="addnewtext" max-width="430px" :key="modalkey">
      <AddNewText
        ref="addNewText"
        @closeModal="addnewtext = !addnewtext"
        :loading="loading"
        @postDailyText="postDailyText"
      />
    </v-dialog>

    <v-dialog v-model="editnewtext" max-width="430px" :key="modalkey">
      <EditText
        ref="editText"
        :content="dailytext.content"
        @closeModal="editnewtext = !editnewtext"
        @postDailyText="handleUpdateDailyText"
        :loading="menuloading"
      />
    </v-dialog>

    <v-dialog v-model="scheduletext" max-width="430px" :key="modalkey">
      <ScheduleText
        @closeModal="scheduletext = !scheduletext"
        :loading="loading"
        @postDailyText="postDailyText"
      />
    </v-dialog>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import DailyTextMenu from "@/components/DailyText/DailyTextMenu.vue";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import { BRANDCOLOR } from "@/constants/constants";
import AddNewText from "@/elements/Modals/AddNewText.vue";
import AddNewBulkSMS from "@/elements/Modals/AddNewBulkSMS.vue";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import dailytextService from "@/services/DailyText/dailytextService";
import ScheduleText from "@/elements/Modals/ScheduleText.vue";
import EditText from "@/elements/Modals/EditText.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    DailyTextMenu,
    OverlayLoader,
    TablePagination,
    PrimaryButton,
    AddNewText,
    AddNewBulkSMS,
    ScheduleText,
    EditText,
  },
  data: () => ({
    COLUMNS: ["ID", "Date Created", "Date Modified", "Content", "Action"],
    menuloading: false,
    BRANDCOLOR,
    addnewtext: false,
    addnewbulksms: false,
    editnewtext: false,
    dailytext: {},
    scheduletext: false,
    modalkey: 0,
  }),

  computed: {
    ...mapState("dailytext", {
      loading: (state) => state.loading,
      dailytexts: (state) => state.dailytexts,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },

  created() {
    this.$store.dispatch("dailytext/fetchDailyTexts");
  },

  methods: {
    addNew() {
      this.modalkey++;
      this.addnewtext = true;
    },
    addBulkSMS() {
      this.modalkey++;
      this.addnewbulksms = true;
    },
    setLoading(value) {
      this.menuloading = value;
    },
    setPagination(e) {
      this.$store.dispatch("request/handlePagination", e);
    },

    async postDailyText(sms) {
      try {
        this.loading = true;
        const response = await dailytextService.postDailyText(
          `/daily-health-content/create`,
          { content: sms }
        );
        handleSuccess(response.data.message);
        this.loading = false;
        this.$store.dispatch("dailytext/fetchDailyTexts");
        this.addnewtext = false;
        this.scheduletext = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },

    async postBulkSMS(sms) {
      try {
        this.loading = true
        const response = await dailytextService.postBulkSMS(
          `/admin/bulk-sms`,
          { message: sms }
        );
        handleSuccess(response.data.message);
        this.loading = false;
        this.$store.dispatch("dailytext/fetchDailyTexts");
        this.addnewbulksms = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },

    updateDailyText(e) {
      this.modalkey++;
      this.dailytext = e;
      this.editnewtext = !this.editnewtext;
    },

    async handleUpdateDailyText(sms) {
      try {
        this.menuloading = true;
        const response = await dailytextService.updateDailyText(
          `/daily-health-content/update/` + this.dailytext.id,
          { content: sms }
        );
        handleSuccess(response.data.message);
        this.menuloading = false;
        this.$store.dispatch("dailytext/fetchDailyTexts");
        this.editnewtext = false;
      } catch (error) {
        handleError(error.message);
        this.menuloading = false;
      }
    },
  },
};
</script>
