<template>
  <div>
    <v-form>
      <div>
        <label>Phone number</label>
        <v-text-field dense v-model="phone" outlined />
      </div>

      <div>
        <label>Address</label>
        <v-text-field dense v-model="address" outlined />
      </div>
    </v-form>

    <div class="my-4">
      <v-btn
        large
        depressed
        @click="editProfile"
        block
        class="brandcolor"
        color="#E9EEF9"
        :loading="loading"
      >
        Edit Profile</v-btn
      >
    </div>
  </div>
</template>


<script>
import { getDate } from "@/utils/formatter";
import { handleError } from "@/utils/handleResponse";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      phone: this.user.phoneNumber,
      address: this.user.address,
      loading: false,
    };
  },

  methods: {
    async editProfile() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/updateProfile", {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          currentDate: getDate(Date.now()),
          gender: this.gender,
          profilePics: this.user.image,
          phoneNumber: this.phone,
          address: this.address,
        });
        this.loading = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>