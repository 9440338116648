<template>
  <div class="revenue-graph-section">
    <h4 class="my-4 font-weight-medium">{{ revenue.title }} for country</h4>
    <table>
      <thead>
        <tr class="activity_revenuetable">
          <td class="text-center">Country</td>
          <td class="text-center">LTV</td>
        </tr>
      </thead>
    </table>

    <div class="activity_revenue_nodata">
      No data for {{ revenue.title }} per country
    </div>
  </div>
</template>



<script>
export default {
  props: {
    revenue: {
      type: Object,
    },
  },
};
</script>