<template>
  <DashboardLayout>
    <div>
      <h2 class="admin-table-title">Doctor’s Sign-up Log (10)</h2>
      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(request, i) in doctorrequests" :key="i">
          <td>{{ request.date }}</td>
          <td>{{ request.email }}</td>
          <td>{{ request.phoneNumber }}</td>
          <td>{{ request.time }}</td>
          <td>
            <primary-button
              @click="showDetails(request)"
              :color="'#BACBEC'"
              :class="'blue--text'"
              >View</primary-button
            >
          </td>
        </tr>
      </AdminTable>

      <div class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <div>
      <v-dialog max-width="580px" v-model="showdetails">
        <DoctorRequest
          :doctor="doctor"
          @closeModal="showdetails = !showdetails"
        />
      </v-dialog>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>



<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import DoctorRequest from "@/elements/Modals/DoctorRequest.vue";
export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    PrimaryButton,
    DoctorRequest,
  },
  data: () => ({
    COLUMNS: ["Date", "E-mail", "Phone Number", "Time", "Action"],
    menuloading: false,
    showdetails: false,
    doctor: {},
  }),

  computed: {
    ...mapState("request", {
      loading: (state) => state.loading,
      doctorrequests: (state) => state.doctorrequests,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },

  created() {
    this.$store.dispatch("request/fetchDoctorRequests");
  },

  methods: {
    setLoading(value) {
      this.menuloading = value;
    },
    setPagination(e) {
      this.$store.dispatch("request/handlePagination", e);
    },

    showDetails(e) {
      this.doctor = e;
      this.showdetails = true;
      this.$store.dispatch("request/fetchSingleDoctorRequest", e.id);
    },
  },
};
</script>