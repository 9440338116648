<template>
  <v-pagination
    ref="pagination"
    :value="page"
    :length="total"
    @input="handleClick"
  ></v-pagination>
</template>


<script>
export default {
  props: {
    page: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },

  methods: {
    handleClick(e) {
      this.$emit("setPagination", e);
    },
  },
};
</script>