<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <div class="text-center">
      <h2>Logout</h2>
      <p>Are you sure you want to logout?</p>

      <img src="@/assets/logout-icon.svg" />
    </div>

    <div class="my-6 button-grid">
      <PrimaryButton
        :color="BRANDCOLOR"
        :class="'blue--text'"
        :large="true"
        :outlined="true"
        @click="$emit('closeModal')"
      >
        No
      </PrimaryButton>
      <PrimaryButton
        @click="handleLogout"
        :large="true"
        :color="'#175CFF'"
        :class="'white--text'"
      >
        Yes
      </PrimaryButton>
    </div>

    <overlay-loader :loading="loading" />
  </v-card>
</template>
    
    
<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import OverlayLoader from "../Loader/OverlayLoader.vue";
export default {
  components: {
    PrimaryButton,
    OverlayLoader,
  },
  props: {
    doctor: {
      type: Object,
    },
  },

  data() {
    return {
      BRANDCOLOR,
    };
  },

  methods: {
    handleLogout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData");
      window.location.href = "/login";
    },
  },
};
</script>