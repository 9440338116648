<template>
  <v-app>
    <v-main class="hidden-md-and-down">
      <v-row style="height: 101%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <NavBar :isMobile="false" ref="nav" :user="user" />
        </v-col>
        <v-col cols="12" md="11" sm="11" lg="11">
          <div class="activity-container">
            <slot></slot>
          </div>
        </v-col>
      </v-row>
    </v-main>
    <!-- mobile screen -->
    <v-main class="hidden-lg-and-up">
      <NavBar :isMobile="true" ref="nav" :user="user" />
      <div class="dashboard-layout">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>
      
      
<script>
import NavBar from "./NavBar.vue";

export default {
  components: {
    NavBar,
  },

  data() {
    return {
      user: {
        firstName: "Admin",
      },
    };
  },
  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
  },
};
</script>