<template>
  <DashboardLayout>
    <div class="my-6 d-flex justify-space-between">
      <h2 class="admin-table-title">Sub users</h2>
      <PrimaryButton
        @click="addsubadmin = true"
        :color="BRANDCOLOR"
        :outlined="true"
      >
        <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Add
        New</PrimaryButton
      >
    </div>

    <div v-if="loading" class="subadmin-grid">
      <div v-for="(card, i) in 4" :key="i">
        <v-skeleton-loader type="card" />
      </div>
    </div>

    <div v-if="!loading" class="subadmin-grid">
      <SubAdminCard
        v-for="(subadmin, i) in subadmins"
        :key="i"
        :subadmin="subadmin"
        @setLoading="setLoading"
      />
    </div>

    <v-dialog persistent max-width="430px" v-model="addsubadmin">
      <AddSubAdmin @closeModal="addsubadmin = false" />
    </v-dialog>

    <OverlayLoader :loading="menuloading"/>
  </DashboardLayout>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import SubAdminCard from "@/components/SubAdmin/SubAdminCard.vue";
import AddSubAdmin from "@/elements/Modals/AddSubAdmin.vue";
import { mapState } from "vuex";
import OverlayLoader from '@/elements/Loader/OverlayLoader.vue';
export default {
  components: {
    DashboardLayout,
    PrimaryButton,
    SubAdminCard,
    AddSubAdmin,
    OverlayLoader
  },
  data: () => ({
    BRANDCOLOR,
    addsubadmin: false,
    menuloading: false
  }),

  created() {
    this.$store.dispatch("subadmin/fetchAdminSubusers");
  },

  computed: {
    ...mapState("subadmin", {
      loading: (state) => state.loading,
      subadmins: (state) => state.subadmins,
    }),
  },

  methods:{
    setLoading(e){
      this.menuloading = e
    }
  }
};
</script>