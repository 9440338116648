<template>
  <DashboardLayout>
    <div>
      <activities-layout>
        <div class="d-flex justify-space-between">
          <h2>Patient Data</h2>
          <div style="width: 190px; margin: -10px 0px 0px 0px">
            <v-select
              style="border-radius: 20px"
              outlined
              placeholder="Upload New Data"
              @click="newdata = !newdata"
            />
          </div>
        </div>

        <GrayTable :columns="COLUMNS" :loading="loading">
          <tr class="table-row" v-for="(request, i) in patients" :key="i">
            <td>{{ request.name }}</td>
            <td>{{ request.email }}</td>
            <td>{{ request.phoneNumber }}</td>
          </tr>
        </GrayTable>
      </activities-layout>

      <v-dialog v-model="newdata" max-width="430px">
        <AddNewPatient @closeModal="newdata = !newdata" />
      </v-dialog>
    </div>
  </DashboardLayout>
</template>
  
  
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import ActivitiesLayout from "@/layouts/activitieslayout/ActivitiesLayout.vue";
import AddNewPatient from "@/elements/Modals/AddNewPatient.vue";
import { mapState } from "vuex";
import GrayTable from '@/elements/Tables/GrayTable.vue';
export default {
  components: {
    DashboardLayout,
    ActivitiesLayout,
    GrayTable,
    AddNewPatient,
  },

  computed: {
    ...mapState("activity", {
      patients: (state) => state.patients,
      loading: (state) => state.loading,
    }),
  },

  data() {
    return {
      COLUMNS: ["Patient name", "Email", "Phone number"],
      newdata: false,
    };
  },
};
</script>