<template>
  <div class="revenue-header">
    <strong>This is just sample data!</strong> To see your own numbers, connect your
    <span class="brandcolor">Paddle account </span> or
    <span class="brandcolor">Stripe account </span>, or add
    <span class="brandcolor">data manually</span>
  </div>
</template>


<script>
export default {
    name:"PaddleBanner"
}
</script>