<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <v-form>
      <div>
        <label>Enter Patient Name</label>
        <v-text-field dense outlined v-model="name" />
      </div>

      <div>
        <label>Enter Patient Email</label>
        <v-text-field dense outlined v-model="email" />
      </div>

      <div>
        <label>Enter Phone Number</label>
        <v-text-field
          type="number"
          inputmode="numeric"
          dense
          outlined
          v-model="phoneNumber"
        />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :outlined="true"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
      >
       Save and Continue
      </PrimaryButton>
    </div>
  </v-card>
</template>
    
    
    <script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
  data: () => ({
    BRANDCOLOR,
    name: "",
    email: "",
    phoneNumber: "",
  }),
};
</script>