import moment from "moment";

export function removeDuplicates(requests) {
  const uniqueRequests = {};
  const filteredRequests = requests.filter((request) => {
    if (uniqueRequests[request.email]) {
      return false;
    } else {
      uniqueRequests[request.email] = true;
      return true;
    }
  });

  return filteredRequests;
}

export function getDate(date) {
  return moment(date).format().split("T")[0];
}

export function isPageActivities() {
  let link = window.location.href.split("/");
  if (link[3] === "activities") {
    return true;
  } else {
    return false;
  }
}

export function formatAmount(amount) {
  return amount.toLocaleString("en");
}
