<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="black" dark v-bind="attrs" v-on="on" text>
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <!-- <v-list-item link> View </v-list-item> -->
      <v-list-item link @click="deleteUser"> Delete </v-list-item>
    </v-list>
  </v-menu>
</template>
  
  
  
  <script>
import { handleError, handleSuccess } from "@/utils/handleResponse";
import subAdminservice from "@/services/SubAdmin/subAdminservice";
export default {
  props: {
    subadmin: {
      type: Object,
    },
  },
  methods: {
    async deleteUser() {
      this.$emit("setLoading", true);
      try {
        const response = await subAdminservice.deleteAdminSubUsers(
          "/pt/profile/delete-sub-user/" + this.subadmin.id
        );
        handleSuccess(response.data.message);
        this.$emit("setLoading", false);
        this.$store.dispatch("subadmin/fetchAdminSubusers");
      } catch (error) {
        handleError(error.response.data.message);
        this.$emit("setLoading", false);
      }
    },
  },
};
</script>