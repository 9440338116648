<template>
  <div class="stat-card">
    <div :style="{ background: color }" class="icon-circle">
      <icons :name="'users'" />
    </div>
    <div>
      <span>{{ title }}</span>
      <h2>{{ number }}</h2>
    </div>
  </div>
</template>



<script>
import Icons from "@/assets/icons/Icons.vue";

export default {
  components: { Icons },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    number: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
};
</script>


<style lang="scss" scoped>
.stat-card {
  display: flex;
  border: 1px solid #c8c6d7;
  border-radius: 10px;
  padding: 18px 16px;
  gap: 8px;
}
.icon-circle {
  border-radius: 50px;
  padding: 20px;
  box-shadow: 0px 8px 10px 0px #00000012;
}

.stat-card > div > h2 {
  color: #1f56c3;
}

.stat-card > div > span{
    color: #808080;
    font-size: 14px
}
</style>