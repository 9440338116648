<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <div>
      <h2 class="my-3">Doctor's Details</h2>

      <div class="d-flex justify-space-between">
        <p>First name</p>
        <p></p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Last name</p>
        <p></p>
      </div>

      <div class="d-flex justify-space-between">
        <p>E-mail</p>
        <p>{{ doctor.email }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Phone number</p>
        <p>{{ doctor.phoneNumber }}</p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Qualification</p>
        <p></p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Practice number</p>
        <p></p>
      </div>

      <div class="d-flex justify-space-between">
        <p>Area of specialization</p>
        <p></p>
      </div>
    </div>

    <div class="my-6 button-grid">
      <PrimaryButton
        :color="'#FFE6E6'"
        :class="'red--text'"
        :large="true"
        @click="rejectRequest"
      >
        Reject
      </PrimaryButton>
      <PrimaryButton
        :large="true"
        :color="'#E9EEF9'"
        :class="'blue--text'"
        @click="acceptRequest"
      >
        Accept
      </PrimaryButton>
    </div>

    <overlay-loader :loading="loading" />
  </v-card>
</template>
    
    
<script>
import { handleError, handleSuccess } from "@/utils/handleResponse";
// import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import requestService from "@/services/Requests/requestService";
import OverlayLoader from "../Loader/OverlayLoader.vue";
export default {
  components: {
    PrimaryButton,
    OverlayLoader,
  },
  props: {
    doctor: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async acceptRequest() {
      this.loading = true;
      try {
        const response = await requestService.updateRequest(
          "/admin/accept-reject-doc-signup-req",
          {
            action: "accept",
            doctorId: this.doctor.id,
            reason: "request approved",
          }
        );
        handleSuccess(response.data.message);
        this.loading = false;
      } catch (error) {
        handleError(error.message);
      }
      this.loading = false;
    },
    async rejectRequest() {
      this.loading = true;
      try {
        const response = await requestService.updateRequest(
          "/admin/accept-reject-doc-signup-req",
          {
            action: "reject",
            doctorId: this.doctor.id,
            reason: "request rejected",
          }
        );
        handleSuccess(response.data.message);
        this.loading = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>