<template>
  <div flat class="settings-options">
    <v-list v-for="(item, i) in settingsmenu" :key="i">
      <v-list-item @click="settingsOption(i+1)">
          <v-list-item-icon><icons :name="item.icon" /></v-list-item-icon>
          <div>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="link">{{ item.link }}</v-list-item-subtitle>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>



<script>
import Icons from "@/assets/icons/Icons.vue";

export default {
  components: { Icons },
  data() {
    return {
      settingsmenu: [
        {
          icon: "account",
          title: "Account Settings",
          link: "Basic Information",
          slug: "account-settings",
        },
        {
          icon: "settings",
          title: "Password settings",
          link: "Change Password",
          slug: "change-password",
        },
      ],
    };
  },

  methods:{
    settingsOption(e){
        this.$emit("settingsOption", e)
    }
  }
};
</script>