<template>
  <div class="settings-component">
    <v-window :value="step">
      <v-window-item :value="1">
        <div>
          <v-tabs v-model="tab" color="#1F56C3" align-tabs="center">
            <v-tab>Personal Information </v-tab>
            <v-tab>Contact Information</v-tab>
          </v-tabs>

          <v-tabs-items class="tabs-container" v-model="tab">
            <v-tab-item> <PersonalInformation :user="user" /></v-tab-item>
            <v-tab-item> <ContactInformation :user="user" /></v-tab-item>
          </v-tabs-items>
        </div>
      </v-window-item>
      <v-window-item :value="2">
        <PasswordForm />
      </v-window-item>
    </v-window>
  </div>
</template>


<script>
import PersonalInformation from "./PersonalInformation.vue";
import ContactInformation from "./ContactInformation.vue";
import PasswordForm from "./PasswordForm.vue";
export default {
  data() {
    return {
      tab: null,
      oldPassword: "",
      newPassword: "",
      confPassword: "",
    };
  },
  props: {
    step: {
      type: Number,
    },
    user: {
      type: Object,
    },
  },
  components: {
    PersonalInformation,
    ContactInformation,
    PasswordForm,
  },
};
</script>