<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <h3 class="py-3">Fill the form to add new sub admin</h3>

    <v-form ref="form">
      <div>
        <label>First Name</label>
        <v-text-field dense outlined v-model="firstName" />
      </div>

      <div>
        <label>Last Name</label>
        <v-text-field dense outlined v-model="lastName" />
      </div>

      <div>
        <label>Email</label>
        <v-text-field dense outlined v-model="email" />
      </div>

      <div>
        <label>Phone</label>
        <v-text-field
          dense
          outlined
          v-model="phone"
          type="number"
          inputmode="numeric"
        />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        @click="addSubadmin"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        :loading="loading"
      >
        Continue
      </PrimaryButton>
    </div>
  </v-card>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import subAdminservice from "@/services/SubAdmin/subAdminservice";
export default {
  components: {
    PrimaryButton,
  },
  data: () => ({
    BRANDCOLOR,
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    loading: false,
  }),

  methods: {
    async addSubadmin() {
      this.loading = true;
      try {
        const response = await subAdminservice.postAdminSubUsers(
          "/pt/profile/add-sub-users",
          {
            lastName: this.lastName,
            firstName: this.firstName,
            email: this.email,
            phoneNumber: this.phone,
          }
        );
        this.loading = false;
        this.$store.dispatch("subadmin/fetchAdminSubusers");
        handleSuccess(response.data.message);
        this.$refs.form.reset()
        this.$emit("closeModal");
      } catch (error) {
        this.loading = false;
        handleError(error.response.data.message);
      }
    },
  },
};
</script>