import { api } from "@/api/api";
import { HEADERS } from "@/constants/constants";
import { handleError, handleSuccess } from "@/utils/handleResponse";

export default {
  async login(data) {
    try {
      const response = await api.post("/auth/login", {
        email: data.email,
        password: data.password,
      });
      if (response.data.token) {
        handleSuccess("Login Successful!. Redirecting...");
      }
      return response.data;
    } catch (error) {
      handleError(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  async fetchAdminDetails(url, value) {
    try {
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + value.token,
        },
      });
      return response.data;
    } catch (error) {
      handleError(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  async updateProfile(url, data) {
    try {
      const response = await api.post(url, data, {
        headers: HEADERS,
      });
      return response.data;
    } catch (error) {
      handleError(error.message);
      throw new Error(error.message);
    }
  },
};
