<template>
  <div class="login-container">
    <div>
      <div class="my-3">
        <Logo />
      </div>
      <LoginForm />
    </div>
  </div>
</template>



<script>
import LoginForm from "../../components/Login/LoginForm.vue";
import Logo from "@/assets/icons/Logo.vue";
export default {
  components: { LoginForm, Logo },
};
</script>