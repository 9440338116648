<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <h2 class="py-3" style="color: #1f56c3">{{ selectedvoucher }}</h2>

    <v-form>
      <div v-if="selectedvoucher === 'Create Bulk Voucher'">
        <label>Upload Excel-sheet / CSV / File</label>
        <v-text-field
          dense
          outlined
          v-model="from"
          placeholder="Click here to upload file"
          append-icon="mdi-link-variant"
        />
      </div>

      <div>
        <label>From</label>
        <v-text-field dense outlined v-model="from" />
      </div>

      <div>
        <label>To</label>
        <v-text-field dense outlined v-model="to" />
      </div>

      <div>
        <label>Start Date</label>
        <v-text-field type="date" dense outlined v-model="email" />
      </div>

      <div>
        <label>Choose Plan</label>
        <v-select placeholder="Choose plan" outlined dense />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :outlined="true"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
      >
        Generate Code
      </PrimaryButton>
    </div>
  </v-card>
</template>
  
  
  <script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
  props: {
    selectedvoucher: {
      type: String,
    },
  },
  data: () => ({
    BRANDCOLOR,
    from: "",
    to: "",
  }),
};
</script>