<template>
  <DashboardLayout>
    <div>
      <activities-layout>
        <div class="pricing-plan-heading-flex">
          <h3 class="grey--text font-weight-medium">Price Plan</h3>

          <v-btn @click="pricingplanmodal = true" outlined :color="BRANDCOLOR"
            ><v-icon class="mr-1">mdi-plus-circle-outline</v-icon> Add New
            Plan</v-btn
          >
        </div>

        <div class="mt-9 pricing-plan-container">
          <h4 class="my-3 grey--text font-weight-medium">Existing Plans</h4>

          <div v-if="loading" class="pricing-plan-grid">
            <div v-for="(_, i) in 9" :key="i">
              <v-skeleton-loader type="card" />
            </div>
          </div>

          <div class="pricing-plan-grid">
            <PricePlan
              v-for="(pricingplan, i) in pricingplans"
              :key="i"
              :pricingplan="pricingplan"
              @editPlan="editPlan(pricingplan)"
            />
          </div>
        </div>
      </activities-layout>

      <v-dialog max-width="500px" v-model="pricingplanmodal">
        <AddPricingPlan
          @closeModal="pricingplanmodal = false"
          :selectedpricingplan="selectedpricingplan"
        />
      </v-dialog>
    </div>
  </DashboardLayout>
</template>
  
  
  <script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import ActivitiesLayout from "@/layouts/activitieslayout/ActivitiesLayout.vue";
import { BRANDCOLOR } from "@/constants/constants";
import PricePlan from "@/components/Activities/PricePlan/PricePlan.vue";
import { mapState } from "vuex";
import AddPricingPlan from "@/elements/Modals/AddPricingPlan.vue";
export default {
  components: {
    DashboardLayout,
    ActivitiesLayout,
    PricePlan,
    AddPricingPlan,
  },
  created() {
    this.$store.dispatch("activity/fetchPricingPlans");
  },
  computed: {
    ...mapState("activity", {
      loading: (state) => state.loading,
      pricingplans: (state) => state.pricingplans,
    }),
  },
  data() {
    return {
      BRANDCOLOR,
      pricingplanmodal: false,
      selectedpricingplan: {
        features: [
          "Full Access to Basic Plan",
          "Dental Care",
          "Eye Care",
          "Health Screenings",
          "Vaccinations and Immunisations",
          "Gynaecology & Many more",
        ],
      },
    };
  },
  methods: {
    editPlan(plan) {
      this.selectedpricingplan = plan;
      this.pricingplanmodal = true;
    },
  },
};
</script>

<style scoped>
@import "@/styles/pricingplan.css";
</style>