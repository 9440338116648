<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="black" dark v-bind="attrs" v-on="on" text>
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link @click="updateDailyText"> Update </v-list-item>
      <v-list-item link @click="deleteDailyText"> Delete </v-list-item>
    </v-list>
  </v-menu>
</template>



<script>
import { handleError, handleSuccess } from "@/utils/handleResponse";
import dailytextService from "@/services/DailyText/dailytextService";
export default {
  props: {
    dailytext: {
      type: Object,
    },
  },
  methods: {
     updateDailyText() {
        this.$emit("updateDailyText", this.dailytext)
    },
    async deleteDailyText() {
      this.$emit("setLoading", true);
      try {
        const response = await dailytextService.deleteDailyText(
          "/daily-health-content/delete/"+this.dailytext.id);
        handleSuccess(response.data.message);
        this.$store.dispatch("dailytext/fetchDailyTexts")
        this.$emit("setLoading", false);
      } catch (error) {
        handleError(error.message);
        this.$emit("setLoading", false);
      }
    },
  },
};
</script>