<template>
  <dashboard-layout>
    <div>
      <h2 class="admin-title">Settings</h2>

      <div class="settings-grid">
        <SettingsOptions :user="user" @settingsOption="settingsOption" />
        <SettingsComponent :user="user" :step="step" />
      </div>
    </div>
  </dashboard-layout>
</template>



<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import SettingsOptions from "@/components/Settings/SettingsOptions.vue";
import SettingsComponent from "@/components/Settings/SettingsComponent.vue";
import { ADMINDETAILS } from "@/constants/constants";
export default {
  components: {
    DashboardLayout,
    SettingsOptions,
    SettingsComponent,
  },
  data() {
    return {
      step: 1,
      user: ADMINDETAILS,
    };
  },

  methods: {
    settingsOption(e) {
      this.step = e;
    },
  },
};
</script>