<template>
  <div class="password-form">
    <div>
      <label>Old Password</label>
      <v-text-field
        v-model="oldPassword"
        outlined
        placeholder="Enter old password"
        dense
      />
    </div>

    <div>
      <label>New Password</label>
      <v-text-field
        v-model="newPassword"
        outlined
        placeholder="Enter New password"
        dense
      />
    </div>

    <div>
      <label>Confirm Password</label>
      <v-text-field
        v-model="confPassword"
        outlined
        placeholder="Enter old password"
        dense
      />
    </div>

    <div class="my-3">
      <v-btn depressed block class="brandcolor" color="#E9EEF9"
        >Edit Password</v-btn
      >
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confPassword: "",
    };
  },
};
</script>