import { MONTHS } from "@/constants/constants";

export default {
  namespaced: true,
  state: {
    monthlysubscribers: {
      labels: MONTHS,
      datasets: [
        {
          label: "MTN",
          data: [200, 140, 90, 120, 104, 210, 170, 110, 130, 320, 125, 300],
          borderColor: "#FFF500",
          backgroundColor: "#FEF2DD",
        },
        {
          label: "Airtel",
          data: [120, 140, 200, 130, 120, 150, 120, 140, 234, 124, 153, 129],
          borderColor: "#FF0000",
          backgroundColor: "#FFD7E9",
        },
      ],
    },
    subscriberdata: {
      labels: MONTHS,
      datasets: [
        {
          label: "MTN",
          data: [0, 120, 140, 200, 130, 120, 150, 120, 140, 234, 124, 153, 129],
          borderColor: "#FFF500",
          backgroundColor: "#FEF2DD",
        },
      ],
    },

    revenuedata: {
      labels: MONTHS,
      datasets: [
        {
          label:"Revenue",
          data: [10, 50, 20, 90, 50, 80, 150, 120, 140, 234, 124, 153, 129],
          borderColor: "#1F56C3",
          backgroundColor: "#BACBEC",
        },
      ],
    },

  },
  mutations: {},
  action: {},
};
