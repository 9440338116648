<template>
  <DashboardLayout>
    <div>
      <h2>Continuous Tracking</h2>
      <div class="my-2">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          outlined
          dense
          style="border-radius: 10px"
          label="Search"
          single-line
        />
      </div>

      <TrackerHeader :title="'Top Doctors'" />
      <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div>

      <div class="my-6">
      <TrackerHeader :title="'Least Favourite Doctor'" />
      <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div>
    </div>
    </div>
  </DashboardLayout>
</template>


<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import TrackerTable from "@/elements/Tables/TrackerTable.vue";
import TrackerHeader from "@/components/Tracker/TrackerHeader.vue";
export default {
  components: {
    DashboardLayout,
    TrackerTable,
    TrackerHeader,
  },

  data() {
    return {
      trackercolumn: ["Name", "Speciality", "Number of Consultation"],
      trackertabledata: [
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Dentist",
          numOfConsultations: 10,
        },
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Surgeon",
          numOfConsultations: 30,
        },
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Dentist",
          numOfConsultations: 40,
        },
      ],
    };
  },
};
</script>