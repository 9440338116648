import { api } from "@/api/api";
import { HEADERS } from "@/constants/constants";

export default {
  async fetchDailyText(url) {
    return api.get(url, {
      headers: HEADERS,
    });
  },

  async updateDailyText(url, data) {
    return api.put(url, data, {
      headers: HEADERS,
    });
  },

  async postDailyText(url, data) {
    return api.post(url, data, {
      headers: HEADERS,
    });
  },

  async postBulkSMS(url, data) {
    return api.post(url, data, {
      headers: HEADERS,
    });
  },

  async manualSendDailyText(url, data) {
    return api.post(url, data, {
      headers: HEADERS,
    });
  },

  async deleteDailyText(url) {
    return api.delete(url, {
      headers: HEADERS,
    });
  },
};
