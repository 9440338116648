import pricingService from "@/services/Pricing/pricingService";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    patients: [
      {
        name: "Dr Shannon",
        email: "DrShannon@gmail.com",
        phoneNumber: "+23493323220",
      },
      {
        name: "Dr Shannon",
        email: "DrShannon@gmail.com",
        phoneNumber: "+23493323220",
      },
    ],
    revenuedata: [
      {
        date: "2023-11-28",
        service: "Betacare Premium",
        subs: "564",
        deactivations: "833",
        activeBase: "91",
        newSub: 170000,
        rebilling: 0,
        totalbilling: 23000,
      },
      {
        date: "2023-11-28",
        service: "Betacare Premium",
        subs: "564",
        deactivations: "833",
        activeBase: "91",
        newSub: 170000,
        rebilling: 0,
        totalbilling: 23000,
      },
      {
        date: "2023-11-28",
        service: "Betacare Premium",
        subs: "564",
        deactivations: "833",
        activeBase: "91",
        newSub: 170000,
        rebilling: 0,
        totalbilling: 23000,
      },
    ],

    revenues:[
      {
        title:"Monthly Recuring Revenue",
        amount: 4000.08,
        percentage: 30.23
      },
      {
        title:"MRR Movement Gross",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Net Revenue",
        amount: 4000.08,
        percentage: -30.23
      },
      {
        title:"Gross Revenue Churn",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Net Revenue Churn",
        amount: 4000.08,
        percentage: -30.23
      },

      {
        title:"Annual Run Rate",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Lifetime value",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Average Revenue Per Customer",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Cohort analysis: Paying Customer",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Paying Customers",
        amount: 4000.08,
        percentage: -30.23
      },

      {
        title:"New Paying Customers",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Customer Churn",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Refunds",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"New Month Recurring Revenue",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Churned Monthly Recurring Revenue",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Expansion Monthly Recurring Revenue",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Failed Charges",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Upgrades",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Downgrades",
        amount: 4000.08,
        percentage: 30.23
      },

      {
        title:"Cancellations",
        amount: 4000.08,
        percentage: 30.23
      },


      

    ],

    activitystat: [
      { title: "Total Active Accounts", icon: "users", number: 0 },
      { title: "Active Users", icon: "users", number: 0 },
      { title: "Active Doctors", icon: "users", number: 0 },
    ],

    patientactivity: [
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
    ],

    doctoractivity: [
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
      {
        email: "Drshannon@gmail.com",
        phone: "+234708900712",
        login: "05:07 PM",
        duration: "1 hour",
      },
    ],

    pricingplans: [],
    loading: false,
  },

  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_PRICING_PLANS(state, value) {
      state.pricingplans = value;
    },
  },

  actions: {
    fetchPricingPlans: async ({ commit }) => {
      commit("SET_LOADING", true);
      try {
        const response = await pricingService.fetchPricingPlans(
          "/payment/get-plans"
        );
        commit("SET_LOADING", false);
        commit("SET_PRICING_PLANS", response.data.data);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },
  },
};
