import { TOKEN } from "@/constants/constants";

export const isAuthenticated = () => {
  if (TOKEN == null) {
    return false;
  }
  const tokenParts = TOKEN.split(".");
  if (tokenParts.length !== 3) {
    return false;
  }
  if (!TOKEN.startsWith("eyJ")) {
    return false;
  }
  return true;
};
