<template>
  <div class="pricing-plan-card">
    <h4 class="grey--text font-weight-medium">{{ pricingplan.name }}</h4>

    <div>
      <h2 class="pricing-plan-amount">
        ₦ {{ pricingplan.amount | formatAmount
        }}<span class="pricing-plan-per">/{{ pricingplan.interval }}</span>
      </h2>
    </div>
    <p class="grey--text">{{ pricingplan.description }}</p>

    <!-- <ul
      class="pricing-plan-customlist"
      v-for="(feature, i) in pricingplan.subscriptions"
      :key="i"
    >
      <li>{{ feature }}</li>
    </ul> -->

    <div class="pricing-plan-edit">
      <v-btn @click="editPlan" block outlined :color="BRANDCOLOR">Edit</v-btn>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { BRANDCOLOR } from "@/constants/constants";
import { formatAmount } from "@/utils/formatter";
Vue.filter("formatAmount", formatAmount);
export default {
  data() {
    return {
      BRANDCOLOR,
    };
  },
  props: {
    pricingplan: {
      type: Object,
    },
  },
  methods: {
    editPlan() {
      this.$emit("editPlan");
    },
  },
};
</script>