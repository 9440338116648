<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    class="rounded-lg primarybtn"
    @click="click"
    depressed
    :block="block"
    :x-large="large"
    :color="color"
    :outlined="outlined"
    :class="outlined ? '' : 'white--text'"
  >
    <slot></slot>
  </v-btn>
</template>
  
  
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "PrimaryButton",
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    block: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    outlined: {
      type: Boolean,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
});
</script>
  
  