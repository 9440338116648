<template>
  <div class="table-width">
    <table class="border-collapse: collapse;" cellspacing="0">
      <thead class="table-heading">
        <tr>
          <th v-for="(column, i) in columns" :key="i">
            {{ column }}
          </th>
        </tr>
      </thead>

      <tbody v-if="!loading">
        <slot></slot>
      </tbody>

      <tbody v-if="loading">
        <tr v-for="(_, i) in 10" :key="i" class="text-center">
          <td class="table-loader" v-for="(_, i) in columns" :key="i">
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  props: {
    columns: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>





<style scoped>
table {
  width: 100% !important;
  border-collapse: collapse !important;
}
th,
td {
  border: 1px solid #b9b9b9;
  padding: 8px;
  text-align: left;
}

.table-width {
  width: 100% !important;
  overflow: auto;
}

.table-heading {
  width: 100%;
  font-weight: 300;
  background: #0000000f;
}

.table-heading > tr > th {
  padding: 20px;
  text-align: start;
  border-right: 1px solid #b9b9b9;
}

.table-row > td {
  padding: 20px;
}

.table-heading th {
  border-right: 1px solid #b9b9b9 !important;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
}

.table-heading th:first-child {
  border-radius: 10px 0 0 0px;
}

.table-heading th:last-child {
  border-radius: 0 10px 0px 0;
  border-right: none !important;
}
</style>