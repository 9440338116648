<template>
  <DashboardLayout>
    <div>
      <activities-layout>
        <h3>VAS Revenue</h3>

        <div class="btn-grid">
          <v-btn depressed color="#4BB964" class="white--text"
            >Download Excel</v-btn
          >
          <v-btn depressed color="#DE4A4A" class="white--text"
            >Download Pdf</v-btn
          >
          <v-btn depressed color="#F2C629" class="white--text">Print</v-btn>
        </div>

        <GrayTable :columns="revenuecolumn">
          <tr colspan="3" v-for="(revenue, i) in revenuedata" :key="i">
            <td>{{ revenue.date }}</td>
            <td>{{ revenue.service }}</td>
            <td>{{ revenue.subs }}</td>
            <td>{{ revenue.deactivations }}</td>
            <td>{{ revenue.activeBase }}</td>
            <td>{{ revenue.newSub }}</td>
            <td>{{ revenue.rebilling }}</td>
            <td>{{ revenue.totalbilling }}</td>
          </tr>
        </GrayTable>

        <div class="revenue-grid mt-5">
          <div v-for="(revenue, i) in revenues" :key="i">
            <RevenueCard :revenue="revenue" />
          </div>
        </div>
      </activities-layout>
    </div>
  </DashboardLayout>
</template>


<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import ActivitiesLayout from "@/layouts/activitieslayout/ActivitiesLayout.vue";
import GrayTable from "@/elements/Tables/GrayTable.vue";
import { REVENUECOLUMN } from "@/constants/constants";
import { mapState } from "vuex";
import RevenueCard from "@/elements/Cards/RevenueCard.vue";
export default {
  components: {
    DashboardLayout,
    ActivitiesLayout,
    GrayTable,
    RevenueCard,
  },
  data() {
    return {
      revenuecolumn: REVENUECOLUMN,
    };
  },
  computed: {
    ...mapState("activity", {
      revenues: (state) => state.revenues,
      revenuedata: (state) => state.revenuedata,
    }),
  },
};
</script>


<style scoped>
.btn-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  margin: 15px 0px;
  max-width: 500px;
  overflow: auto;
}

.btn-grid > *{
  min-width: 150px !important
}
</style>