<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="closeModal" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <h2 class="py-3 text-center">Send Daily SMS</h2>

    <v-form ref="form">
      <div>
        <label>Enter content</label>
        <v-textarea dense outlined v-model="sms" />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="postDailyText"
      >
        Continue
      </PrimaryButton>
    </div>
  </v-card>
</template>
  
  
  <script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton
  },

  props: {
    loading: {
      type: Boolean,
    }
  },

  data() {
    return {
      BRANDCOLOR,
      sms: "",
      overlayloading: true,
    };
  },

  methods: {
    setSms(e) {
      this.sms = e;
    },
    postDailyText() {
      this.$emit("postDailyText", this.sms);
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>