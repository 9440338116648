<template>
  <div class="revenue-graph-section">
    <div class="d-flex justify-space-between">
      <span style="font-size: 14px">
        {{ revenue.title }} between December 26th, 2023 and January 25th, 2024
      </span>
      <div>
        <Icons :name="'help-circle'" /> <Icons :name="'more-vertical'" />
      </div>
    </div>

    <hr />
    <PaddleBanner />

    <div class="my-6 text-center">
      <h2 class="font-weight-medium">
        {{ revenue.title }}: <strong>$12,602.08</strong>
        <span class="negative-percentage">(30.23%)</span>
      </h2>
    </div>

    <div>
      <line-chart :datacollection="revenuedata"></line-chart>
    </div>
  </div>
</template>


<script>
import Icons from "@/assets/icons/Icons.vue";
import PaddleBanner from "@/elements/Banner/PaddleBanner.vue";
import LineChart from "@/elements/Graph/LineChart.vue";
import { mapState } from "vuex";
export default {
  name: "RevenueGraphSection",
  components: {
    Icons,
    PaddleBanner,
    LineChart,
  },

  props: {
    revenue: {
      type: Object,
    },
  },

  computed: {
    ...mapState("graph", {
      revenuedata: (state) => state.revenuedata,
    }),
  },
};
</script>