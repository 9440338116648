<template>
  <div @click="viewChart" class="text-center revenuecard" elevation="0">
    <h2>
      ${{ revenue.amount }}
      <span
        :class="
          revenue.percentage > 0 ? 'positive-percentage' : 'negative-percentage'
        "
        >({{ revenue.percentage }} %)</span
      >
    </h2>
    <p
      style="max-width: 70%; margin: auto; text-align: center; font-size: 14px"
    >
      {{ revenue.title }}
    </p>
  </div>
</template>


<script>
export default {
  props: {
    revenue: {
      type: Object,
    },
  },
  methods: {
    viewChart() {
      this.$emit("goToChart", this.revenue);
    },
  },
};
</script>