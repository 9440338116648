<template>
  <v-list-item>
    <v-list-item-avatar>
      <img :src="notification.image" alt="John" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ notification.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ notification.message }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>


<script>
export default {
  props: {
    notification: {
      type: Object,
    },
  },
};
</script>