<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          depressed
          color="white"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
        >
          <div top left>
            <template>
              <Icon :name="'notification'" />
            </template>
          </div>
        </v-btn>
      </template>

      <v-card max-width="455px">
        <v-list>
          <NotificationItem
            v-for="(notification, i) in notifications"
            :key="i"
            :notification="notification"
          />
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
  
  
  
<script>
import Icon from "@/assets/icons/Icons.vue";
import NotificationItem from "@/elements/Notification/NotificationItem.vue";
import { mapState } from "vuex";
export default {
  name: "NotificationsComponent",
  components: { Icon, NotificationItem },
  computed: {
    ...mapState("auth", {
      notifications: (state) => state.notifications,
    }),
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
    };
  },
};
</script>