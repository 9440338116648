import Vue from "vue";
export function handleSuccess(message) {
  Vue.prototype.$toast.clear();
  Vue.prototype.$toast.success(message, {
    icon: true,
  });
}

export function handleError(message) {
  Vue.prototype.$toast.clear();
  Vue.prototype.$toast.error(message, {
    icon: true,
  });
}
