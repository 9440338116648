<template>
  <div>
    <div class="d-flex justify-center">
      <div>
        <v-avatar @click="uploadImage" size="120" :color="BRANDCOLOR">
          <span v-if="!image" class="text-h3 white--text">AD</span>
          <img v-if="image" :src="image" />
        </v-avatar>
        <div class="my-3 text-center">
          <v-btn
            @click="uploadImage"
            class="brandcolor"
            depressed
            color="#E9EEF9"
            ><icons :name="'upload'" :class="'mr-2'" /> Upload</v-btn
          >
          <input
            hidden
            ref="image"
            type="file"
            accept="image"
            @change="setImage"
          />
        </div>
      </div>
    </div>

    <div>
      <label>First name</label>
      <v-text-field dense outlined v-model="firstname" />
    </div>

    <div>
      <label>Last name</label>
      <v-text-field dense outlined v-model="lastname" />
    </div>

    <div>
      <label>Gender</label>
      <v-radio-group row v-model="gender">
        <v-radio label="Male" value="MALE"></v-radio>
        <v-radio label="Female" value="FEMALE"></v-radio>
      </v-radio-group>
    </div>

    <div class="my-4">
      <v-btn
        large
        depressed
        @click="editProfile"
        block
        class="brandcolor"
        color="#E9EEF9"
        :loading="loading"
      >
        Edit Profile</v-btn
      >
    </div>
  </div>
</template>


<script>
import Icons from "@/assets/icons/Icons.vue";
import { BRANDCOLOR } from "@/constants/constants";
import { getDate } from "@/utils/formatter";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  components: { Icons },
  data() {
    return {
      firstname: this.user.firstName,
      lastname: this.user.lastName,
      email: this.user.email,
      gender: this.user.gender,
      image: this.user.profilePics,
      phoneNumber: this.user.phoneNumber,
      address: this.user.address,
      loading: false,
      BRANDCOLOR,
    };
  },

  methods: {
    async editProfile() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/updateProfile", {
          firstName: this.firstname,
          lastName: this.lastname,
          email: this.email,
          currentDate: getDate(Date.now()),
          gender: this.gender,
          profilePics: this.image,
          phoneNumber: this.phoneNumber,
          address: this.address,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    uploadImage() {
      this.$refs.image.click();
    },

    setImage() {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.$refs.image.files[0]);
    },
  },
};
</script>