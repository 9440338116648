<template>
  <div>
    <div>
      <v-btn :color="BRANDCOLOR" depressed small @click="goBack" fab>
        <v-icon class="white--text">mdi-arrow-left </v-icon>
      </v-btn>
    </div>
    <div class="text-center">
      <h2 style="font-weight: 400">
        The most important, accurate metrics for Betacare.
      </h2>

      <PaddleBanner />

      <div class="revenue-grid mt-5 pa-4">
        <div v-for="(revenue, i) in revenues" :key="i">
          <RevenueCard :revenue="revenue" @goToChart="goToChart" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import RevenueCard from "@/elements/Cards/RevenueCard.vue";
import PaddleBanner from "@/elements/Banner/PaddleBanner.vue";
import { mapState } from "vuex";
import { BRANDCOLOR } from "@/constants/constants";
export default {
  components: {
    RevenueCard,
    PaddleBanner,
  },

  data() {
    return {
      BRANDCOLOR
    };
  },
  computed: {
    ...mapState("activity", {
      revenues: (state) => state.revenues,
      revenuedata: (state) => state.revenuedata,
    }),
  },

  methods: {
    goBack() {
      this.$emit("goBack");
    },
    goToChart(e) {
      this.$emit("goToChart",e);
    },
  },
};
</script>
