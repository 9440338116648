import pricingService from "@/services/Pricing/pricingService";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    pricingplans: [],
    loading: false,
  },
  mutations: {
    SET_PRICING_PLANS(state, value) {
      state.pricingplans = value;
    },
  },
  actions: {
    fetchPricingPlans: async ({ commit }) => {
      try {
        await pricingService.fetchPricingPlans(
          "/payment/get-plans"
        );
        commit("SET_LOADING", true);
        commit("SET_PRICING_PLANS");
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },
  },
};
