<template>
  <div class="activities-navigation">
    <v-navigation-drawer
      fixed
      absolute
      :permanent="!isMobile"
      :temporary="isMobile"
      left
      v-model="drawer"
      width="140"
    >
      <div class="mt-6 mx-6">
        <h3 class="admin-title">Activities</h3>
      </div>
      <div class="navmenu-list">
        <v-list>
          <v-list-item-group v-for="(item, i) in menu" :key="i">
            <v-list-item
              :to="item.link"
              link
              :active-class="'activitiesblueBg'"
              class="menu-item"
            >
              <v-list-item-content :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>
        
<script>
import { BRANDCOLOR, DEFAULTICONCOLOR } from "@/constants/constants";
export default {
  name: "NavBar",
  props: {
    isMobile: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
  },
  data: () => ({
    DEFAULTICONCOLOR,
    BRANDCOLOR,
    menu: [
      {
        title: "Revenue",
        link: "/activities/revenue",
      },

      { title: "Price Plan", link: "/activities/price-plan" },

      {
        title: "Data",
        link: "/activities/data",
      },

      {
        title: "Voucher",
        link: "/activities/voucher",
      },
    ],
    drawer: false,
    group: null,
    userData: "",
    name: "",
    image: "",
    loading: false,
    open: false,
    value: false,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    showDrawer() {
      this.drawer = !this.drawer;
    },
    handleLogout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("ADMIN_DETAILS");
      window.location.href = "/login";
    },
    showValue() {
      this.open = !this.open;
    },
  },
};
</script>
        
        