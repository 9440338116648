export const BRANDCOLOR = "#1F56C3";
export const DEFAULTICONCOLOR = "#808080";
export const TOKEN = sessionStorage.getItem("token");
export const ADMINDETAILS = JSON.parse(sessionStorage.getItem("ADMIN_DETAILS"));

export const HEADERS = {
  Authorization: `Bearer ${TOKEN}`,
};

export const NETWORKS = ["MTN", "Airtel", "Glo", "9mobile"];

export const STATS = [
  { title: "Total Registered Users", icon: "users", number: 0 },
  { title: "Registered Doctors", icon: "users", number: 0 },
  { title: "Inactive subscribers", icon: "users", number: 0 },
];

export const SUBSCRIBERS = [
  {
    title: "All subscriptions",
    number: 120,
    networks: [
      { name: "MTN users", icon: "mtn", number: 10 },
      { name: "Airtel users", icon: "airtel", number: 10 },
    ],
  },

  {
    title: "Active subscribers",
    number: 120,
    networks: [
      { name: "MTN users", icon: "mtn", number: 10 },
      { name: "Airtel users", icon: "airtel", number: 10 },
    ],
  },

  {
    title: "Unsubscribed users",
    number: 120,
    networks: [
      { name: "MTN users", icon: "mtn", number: 10 },
      { name: "Airtel users", icon: "airtel", number: 10 },
    ],
  },
];

export const ADMINTABLECOLUMNS = ["Date", "Email", "Phone Number", "Time"];

export const ADMINTABLEROWS = [
  {
    date: "2023-11-15",
    email: "Drshannon@gmail.com",
    phone: "+23497001121",
    time: "05:06 PM",
  },
  {
    date: "2023-11-15",
    email: "Drshannon@gmail.com",
    phone: "+23497001121",
    time: "05:06 PM",
  },
];

export const MONTHS = [
  "JAN",
  "FEB",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUG.",
  "SEPT.",
  "OCT.",
  "NOV.",
  "DEC.",
];

export const REVENUECOLUMN = [
  "Date",
  "Service Name",
  "New Subs",
  "Deactivation",
  "Active Base",
  "New Subs Billing",
  "Re Billing",
  "Total Billing",
];

export const PATIENTACTIVITYCOLUMN = [
  "Email",
  "Phone number",
  "Log-in time",
  "Duration",
];

export const PRICINGDURATIONS = [
  "hourly",
  "monthly",
  "Visit",
  "daily",
  "yearly",
  "6months",
  "annually",
  "biannually",
];
