import dailytextService from "@/services/DailyText/dailytextService";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    loading: false,
    dailytexts: []
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_DAILY_TEXT(state,value){
        state.dailytexts = value
    }
  },
  actions: {
    fetchDailyTexts: async ({ commit }) => {
        try {
          commit("SET_LOADING", true);
          const response = await dailytextService.fetchDailyText(
            `/daily-health-content/all-active`
          );
          commit("SET_DAILY_TEXT", response.data.data)
          commit("SET_LOADING", false);
        } catch (error) {
          commit("SET_LOADING", false);
          handleError(error.message);
        }
      }
  },
};
