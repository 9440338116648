<template>
  <div class="subadmin-card">
    <div class="d-flex justify-end">
      <SubAdminMenu :subadmin="subadmin" @setLoading="setLoading"/>
    </div>
    <div class="d-flex justify-center text-center">
      <div>
        <v-avatar size="60" :color="BRANDCOLOR">
          <span class="text-h5 white--text">{{
            subadmin.firstName.slice(0, 2)
          }}</span>
        </v-avatar>

        <h2>{{ subadmin.firstName }} {{ subadmin.lastName }}</h2>
        <p>{{ subadmin.subUser.userType }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import { BRANDCOLOR } from "@/constants/constants";
import SubAdminMenu from "./SubAdminMenu.vue";

export default {
  props: {
    subadmin: {
      type: Object,
    },
  },
  components: {
    SubAdminMenu,
  },

  data() {
    return {
      BRANDCOLOR,
    };
  },

  methods:{
    setLoading(e){
      this.$emit("setLoading",e)
    }
  }
};
</script>