<template>
  <div class="admin-dashboard-card">
    <h2>{{ title }}</h2>

    <div>{{ description }}</div>
  </div>
</template>


<script>
export default {
  name: "AdminDashboardCard",
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>