<template>
  <div class="tracker-header">
    <div class="mt-3 admin-title">
      {{ title }}
    </div>
    <div class="admin-filter">
      <v-select flat dense solo label="Filter" prepend-inner-icon="mdi-filter"></v-select>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>