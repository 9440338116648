<template>
  <DashboardLayout>
    <div>
      <activities-layout>
        <v-window v-model="step">
          <v-window-item :value="1">
            <div class="d-flex justify-space-between">
              <div>
                <h3>Activities</h3>
                <div class="grey--text">
                  Stay up to date with what’s happening
                </div>
              </div>

              <div>
                <PrimaryButton @click="step = 2" :color="'#E9EEF9'">
                  <span class="brandcolor"
                    >Monitor revenue
                  </span></PrimaryButton
                >
              </div>
            </div>

            <div class="mt-8 d-flex justify-space-between">
              <h4>Today</h4>

              <div>{{ Date.now() | getDate }}</div>
            </div>

            <div class="admin-stat-cards">
              <StatCard
                v-for="(stat, i) in activitystat"
                :key="i"
                :title="stat.title"
                :icon="stat.icon"
                :number="stat.number"
                :color="'#E9EEF9'"
              />
            </div>

            <h3 class="grey--text font-weight-medium">
              Daily Subscription report
            </h3>

            <div class="admin-graph-flex">
              <div>
                <div class="my-2 d-flex justify-space-between">
                  <h2 class="mt-2 admin-table-title">Daily subscribes</h2>
                  <select :items="NETWORKS">
                    <option v-for="(network, i) in NETWORKS" :key="i">
                      {{ network }} users
                    </option>
                  </select>
                </div>
                <LineChart :datacollection="monthlysubscribers" />
              </div>

              <div>
                <div class="my-2 d-flex justify-space-between">
                  <h2 class="mt-2 admin-table-title">Daily unsubscribes</h2>
                  <select :items="NETWORKS">
                    <option v-for="(network, i) in NETWORKS" :key="i">
                      {{ network }} users
                    </option>
                  </select>
                </div>
                <LineChart :datacollection="subscriberdata" />
              </div>
            </div>

            <div>
              <h3 class="grey--text font-weight-medium my-3">
                Patient’s Activity
              </h3>
              <AdminTable :columns="PATIENTACTIVITYCOLUMN">
                <tr
                  class="table-row"
                  v-for="(request, i) in patientactivity"
                  :key="i"
                >
                  <td>{{ request.email }}</td>
                  <td>{{ request.phone }}</td>
                  <td>{{ request.login }}</td>
                  <td>{{ request.duration }}</td>
                </tr>
              </AdminTable>
            </div>

            <div>
              <h3 class="grey--text font-weight-medium my-3 mt-6">
                Doctor’s Activity
              </h3>
              <AdminTable :columns="PATIENTACTIVITYCOLUMN">
                <tr
                  class="table-row"
                  v-for="(request, i) in patientactivity"
                  :key="i"
                >
                  <td>{{ request.email }}</td>
                  <td>{{ request.phone }}</td>
                  <td>{{ request.login }}</td>
                  <td>{{ request.duration }}</td>
                </tr>
              </AdminTable>
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <RevenueActivities @goBack="step = 1" @goToChart="goToChart" />
          </v-window-item>

          <v-window-item :value="3">
            <RevenueGraph :revenue="revenue" @goBack="step = 2" />
          </v-window-item>
        </v-window>
      </activities-layout>
    </div>
  </DashboardLayout>
</template>


<script>
import Vue from "vue";
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import ActivitiesLayout from "@/layouts/activitieslayout/ActivitiesLayout.vue";
import { REVENUECOLUMN, NETWORKS } from "@/constants/constants";
import { getDate } from "@/utils/formatter";
import StatCard from "@/elements/Cards/StatCard.vue";
import LineChart from "@/elements/Graph/LineChart.vue";
import { mapState } from "vuex";
import { PATIENTACTIVITYCOLUMN } from "@/constants/constants";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import RevenueActivities from "@/views/Activities/RevenueActivities.vue";
import RevenueGraph from "@/components/Activities/Revenue/RevenueGraph.vue";
Vue.filter("getDate", getDate);

export default {
  components: {
    DashboardLayout,
    ActivitiesLayout,
    StatCard,
    LineChart,
    AdminTable,
    PrimaryButton,
    RevenueActivities,
    RevenueGraph,
  },
  data() {
    return {
      revenuecolumn: REVENUECOLUMN,
      NETWORKS,
      PATIENTACTIVITYCOLUMN,
      step: 1,
      revenue: "",
    };
  },
  computed: {
    ...mapState("graph", {
      monthlysubscribers: (state) => state.monthlysubscribers,
      subscriberdata: (state) => state.subscriberdata,
    }),

    ...mapState("activity", {
      activitystat: (state) => state.activitystat,
      patientactivity: (state) => state.patientactivity,
      doctoractivity: (state) => state.doctoractivity,
    }),
  },

  methods: {
    goToChart(e) {
      this.revenue = e;
      this.step = 3;
    },
  },
};
</script>


<style scoped>
.btn-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 10px 0px;
  max-width: 500px;
}
</style>