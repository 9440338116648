<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <h2 class="py-3 text-center">Schedule SMS</h2>

    <v-form>
      <div>
        <label>Enter content</label>
        <v-textarea dense outlined v-model="sms" />
      </div>

      <div>
        <label>Set time</label>
        <v-text-field outlined v-model="time" type="datetime-local" dense />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="postDailyText"
      >
        Continue
      </PrimaryButton>
    </div>
  </v-card>
</template>
  
  
  <script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
    dailytext: {
      type: [Object, String],
    },
  },
  data() {
    return {
      BRANDCOLOR,
      sms: "",
      time: "",
    };
  },

  methods: {
    postDailyText() {
      this.$emit("postDailyText", this.sms);
    },
  },
};
</script>