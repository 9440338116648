<template>
  <div>
    <line-chart :chart-data="datacollection" :options="options"></line-chart>
  </div>
</template>
  
<script>
import LineChart from "./LineChart.js";

export default {
  name: "ChartComponent",
  components: {
    LineChart,
  },

  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines:{
                display: true
              },
              ticks: {
                beginAtZero: true,
                max: 400,
                stepSize: 50
              },
            },
          ],

           xAxes: [
            {
              gridLines:{
                display: false
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  props: {
    datacollection: {
      type: [Object, Array],
    },
  },
};
</script>
  


  