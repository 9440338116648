import subAdminservice from "@/services/SubAdmin/subAdminservice";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    loading: false,
    subadmins: [],
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_SUBADMIN(state, value) {
      state.subadmins = value;
    },
  },
  actions: {
    fetchAdminSubusers: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await subAdminservice.fetchAdminSubusers(
          "/pt/profile/get-sub-users"
        );
        commit("SET_SUBADMIN", response.data.subUsers);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
  },
};
