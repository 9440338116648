import Vue from "vue";
import Meta from "vue-meta";
import VueRouter from "vue-router";
import { isAuthenticated } from "@/utils/handleAuth";
import LoginView from "@/views/Auth/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import DoctorRequestView from "@/views/DoctorRequestView.vue";
import DailyTextView from "@/views/DailyTextView.vue";
import SubAdminView from "@/views/SubAdminView.vue";
import ActivitiesView from "@/views/Activities/ActivitiesView.vue";
import RevenueView from "@/views/Activities/RevenueView.vue";
import TrackerView from "@/views/TrackerView.vue";
import SettingsView from "@/views/SettingsView.vue";
import PricePlanView from "@/views/Activities/PricePlanView.vue";
import DataView from "@/views/Activities/DataView.vue";
import VoucherView from "@/views/Activities/VoucherView.vue";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
    meta: { isAuth: true },
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { isAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },

  {
    path: "/doctor-request",
    name: "doctor-request",
    component: DoctorRequestView,
    meta: { requiresAuth: true },
  },

  {
    path: "/daily-text",
    name: "daily-text",
    component: DailyTextView,
    meta: { requiresAuth: true },
  },

  {
    path: "/subadmins",
    name: "subadmins",
    component: SubAdminView,
    meta: { requiresAuth: true },
  },

  {
    path: "/activities",
    name: "activities",
    component: ActivitiesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/activities/revenue",
    name: "activities",
    component: RevenueView,
    meta: { requiresAuth: true },
  },

  {
    path: "/activities/price-plan",
    name: "price-plan",
    component: PricePlanView,
    meta: { requiresAuth: true },
  },

  {
    path: "/activities/data",
    name: "data",
    component: DataView,
    meta: { requiresAuth: true },
  },

  {
    path: "/activities/voucher",
    name: "voucher",
    component: VoucherView,
    meta: { requiresAuth: true },
  },

  {
    path: "/tracker",
    name: "tracker",
    component: TrackerView,
    meta: { requiresAuth: true },
  },

  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//prevent not logged-in users from accessing dashboard pages
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next("/login");
  } else next();
});

//prevent logged in users from accessing auth pages
router.beforeEach((to, from, next) => {
  if (to.meta.isAuth && isAuthenticated()) {
    next("/dashboard");
  } else next();
});

export default router;
