<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('closeModal')" depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <v-form>
      <div>
        <label>Enter {{ title ? "" : "New" }} Plan Name</label>
        <v-text-field v-model="name" dense outlined />
      </div>

      <!-- <div>
        <label>Enter Benefits</label>
        <div class="pricing-plan-edit-features">
          <ul v-for="(feature, i) in selectedpricingplan.features" :key="i">
            <li class="list-none">{{ feature }}</li>
          </ul>
        </div>
      </div> -->

      <div>
        <label>Enter Price</label>
        {{ selectedpricingplan.price }}
        <v-text-field
          prefix="₦"
          :value="selectedpricingplan.price"
          dense
          outlined
          v-model="amount"
        />
      </div>

      <div>
        <label>Enter Duration </label>
        <v-select dense outlined v-model="interval" :items="PRICINGDURATIONS" />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :outlined="true"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        :loading="loading"
        @click="createPlan"
      >
        {{ title ? "Add New Plan" : "Add New Plan" }}
      </PrimaryButton>
    </div>
  </v-card>
</template>
    
    
<script>
import { BRANDCOLOR, PRICINGDURATIONS } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import pricingService from "@/services/Pricing/pricingService";
export default {
  components: {
    PrimaryButton,
  },

  props: {
    selectedpricingplan: {
      type: Object,
    },
  },
  data: () => ({
    BRANDCOLOR,
    PRICINGDURATIONS,
    loading: false,
    interval: "",
    amount: "",
    name: "",
  }),

  created() {
    (this.title = this.selectedpricingplan.title),
      (this.price = this.selectedpricingplan.price),
      (this.duration = this.selectedpricingplan.per
        ? this.selectedpricingplan.per
        : "Monthly");
  },

  methods: {
    async createPlan() {
      try {
        this.loading = true;
        await pricingService.createPricingPlan("/createplan", {
          amount: parseFloat(this.amount),
          interval: this.interval,
          name: this.name,
        });
        handleSuccess("Plan added successfully")
        this.loading = false;
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>