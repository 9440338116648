<template>
  <div class="subscription-card">
    <div class="subscription-title">
      <h3>{{ title }}</h3>
      <h3 class="font-weight-bold  black--text">{{ number }}</h3>
    </div>

    <div class="networks">
      <div
        v-for="(network, i) in networks"
        :key="i"
        class="d-flex justify-space-between mt-3 gap-2"
      >
        <div class="mr-4">
          <icons :name="network.icon" /> {{ network.name }}
        </div>
        <div class="font-weight-bold">{{ network.number }}</div>
      </div>
    </div>
  </div>
</template>



<script>
import Icons from "@/assets/icons/Icons.vue";
export default {
  components: { Icons },
  props: {
    title: {
      type: String,
    },
    number: {
      type: Number,
    },
    networks: {
      type: Array,
    },
  },
};
</script>


<style scoped>
.subscription-card {
  border: 1px solid#C8C6D7;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px #00000014;
  padding: 28px 18px;
  height: 220px
}

.subscription-card > .subscription-title {
  display: flex;
  font-weight: 400;
  justify-content: space-between;
}

.subscription-card > .subscription-title > h3 {
  font-weight: 500;
  font-size: 17px;
  color: #808080;
}

.networks {
  border: 1px solid #c8c6d7;
  border-radius: 8px;
  padding: 12px;
  margin: 10px 0px;
}
</style>