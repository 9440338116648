<template>
  <DashboardLayout>
    <div>
      <activities-layout>
        <div class="d-flex justify-space-between">
          <h2>Generate Voucher</h2>
          <div style="width: 190px; margin: -10px 0px 0px 0px">
            <v-select
              style="border-radius: 20px"
              outlined
              placeholder="Create Voucher"
              :items="vouchers"
              v-model="selectedvoucher"
            />
          </div>
        </div>

        <div class="text-center my-6">
          <h2 class="admin-voucher-title">You don’t have any active voucher</h2>
          <v-btn @click="voucher = !voucher" text :color="BRANDCOLOR"
            >Click here to generate one</v-btn
          >
        </div>
      </activities-layout>

      <v-dialog v-model="voucher" max-width="430px">
        <CreateVoucher
          :selectedvoucher="selectedvoucher"
          @closeModal="voucher = false"
        />
      </v-dialog>
    </div>
  </DashboardLayout>
</template>
  
  
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import ActivitiesLayout from "@/layouts/activitieslayout/ActivitiesLayout.vue";
import { BRANDCOLOR } from "@/constants/constants";
import CreateVoucher from "@/elements/Modals/CreateVoucher.vue";
export default {
  components: {
    DashboardLayout,
    ActivitiesLayout,
    CreateVoucher,
  },
  data() {
    return {
      BRANDCOLOR,
      voucher: false,
      selectedvoucher: "",
      vouchers: ["Create Single Voucher", "Create Bulk Voucher"],
    };
  },
};
</script>