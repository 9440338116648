<template>
  <div>
    <table cellspacing="0">
      <thead class="table-heading">
        <tr>
          <th v-for="(column, i) in columns" :key="i">{{ column }}</th>
        </tr>
      </thead>

      <tbody v-if="!loading">
        <slot></slot>
      </tbody>
      
      <tbody v-if="loading">
        <tr v-for="(_, i) in 10" :key="i" class="text-center">
          <td class="table-loader" v-for="(_, i) in columns" :key="i">
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  props: {
    columns: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>





<style scoped>
.table-width {
  overflow-x: auto;
  box-shadow: 0px 8px 10px 0px #0000000d;
}

.table-heading {
  width: 100%;
  font-weight: 300;
  border-radius: 10px 10px 0px 0px;
}

.table-heading > tr > th {
  padding: 20px;
  text-align: start;
  border-bottom: 1px dashed black !important
}

.table-row > td {
  padding: 20px;
}

.table-heading th:first-child {
  border-radius: 20px 0 0 0px;
}

.table-heading th:last-child {
  border-radius: 0 20px 0px 0;
}
</style>